import axios from 'axios';
import CryptoJS from 'crypto-js';
import notification from './Notification';
import { hashing,base } from '@/master/hashing.js';
import debug from '@/plugins/Logger.js';

class RequestClient {
  constructor(notification = false) {
    this.showNotification = notification;
    this.baseURL = base;
    //this.baseURL = 'http://localhost:5001/api/';
    this.notificationStack = [];

    // Create and configure a reusable Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.cache = new Map();
    this.cacheExpirationTime = 60 * 1000; // Cache expiration time in milliseconds (e.g., 1 minute)
  }

  token(key) {
    const secret = CryptoJS.lib.WordArray.random(128 / 8);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const hashingArray = CryptoJS.enc.Utf8.parse(hashing);
    const timestamp = Date.now();
    const keyWithTimestamp = { key, timestamp };
    const combinedKey = CryptoJS.lib.WordArray.create()
      .concat(secret)
      .concat(hashingArray);
    const token = CryptoJS.AES.encrypt(JSON.stringify(keyWithTimestamp), combinedKey, {
      iv: iv,
    }).toString();
    return { token, secret, iv, timestamp };
  }

  async connect(method, endpoint, data, key) {
    // Check if the endpoint contains 'https://' or 'http://'
    const isFullURL = /^(https?:\/\/)/.test(endpoint);

    // If it's a full URL, use it as is; otherwise, combine it with the baseURL
    const url = isFullURL ? endpoint : this.baseURL + endpoint;
    const { token, secret, iv } = this.token(key);

    // Use the Axios instance and set specific headers for this request
    const headers = {
      'client-token-key': key,
      'x-content-token': token,
      'x-content-sign': iv.toString(),
      'x-content-key': secret.toString(),
    };

    notification(`${method} Request`, `${endpoint} is Processing...`, 'normal', this.showNotification);

    try {
      // Check if caching is enabled and a cached response is available
      if (method === 'GET' && data && data.cache && this.cache.has(endpoint)) {
        const cachedItem = this.cache.get(endpoint);
        const currentTime = Date.now();

        // Check if the cached item has expired
        if (currentTime - cachedItem.timestamp <= this.cacheExpirationTime) {
          notification(`${method} Request`, `${endpoint} is Cached`, 'info', this.showNotification);
          return cachedItem.response;
        } else {
          // If the cached item has expired, remove it from the cache
          this.cache.delete(endpoint);
        }
      }

      let response;
      if (method === 'DELETE') {
        response = await this.axiosInstance.request({
          method,
          url,
          headers,
        });
      } else {
        response = await this.axiosInstance.request({
          method,
          url,
          headers,
          data,
        });
      }

      // Cache the successful response if caching is enabled
      if (method === 'GET' && data && data.cache) {
        const cachedItem = {
          response,
          timestamp: Date.now(),
        };
        this.cache.set(endpoint, cachedItem);
      }

      notification(`${method} Request`, `${endpoint} is Successful`, 'success', this.showNotification);
      return response;
    } catch (error) {
      debug.error('API request error:', error);
      notification(`${method} Request`, `${endpoint} is Failed`, 'error', this.showNotification);
      throw error;
    }
  }

  async GET(endpoint, key, cache = false) { // Set cache default to false
    return this.connect('GET', endpoint, { cache }, key);
  }

  async POST(endpoint, data, key, cache = false) { // Set cache default to false
    return this.connect('POST', endpoint, { cache, ...data }, key);
  }

  async PUT(endpoint, data, key) {
    return this.connect('PUT', endpoint, data, key);
  }

  async DELETE(endpoint, data, key) {
    debug.log("Delete",key);
    return this.connect('DELETE', endpoint, null, key);
  }
}

debug.log("Client Request :: V0.001 {axios}");
export default RequestClient;
