import { createApp } from 'vue';
import { pageTitle } from 'vue-page-title';
import { key } from './master/host.js';
import mitt from 'mitt';
import App from './App.vue';
import router from './router';
import BackToTop from 'vue-backtotop';
import Toast from "vue-toastification";
import VueSweetalert2 from 'vue-sweetalert2';
import Storage from 'vue-lsp';
import VueCryptojs from 'vue-cryptojs'

import Tooltip from "@programic/vue3-tooltip";
// Import the CSS or use your own!
import '@programic/vue3-tooltip/dist/index.css';

import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";

import 'vue-plyr/dist/vue-plyr.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/css/app.css';
import "vue-toastification/dist/index.css";
import "css-file-icons/build/css-file-icons.css";

import { loadAndStoreConfigData } from '@/plugins/config';
import store from '@/store'

import TooltipPlugin from '@/plugins/TooltipPlugin';
//import '@/plugins/TooltipPlugin.css';

// import your storageManager and requestClient
import storageManager from '@/plugins/storage';
import requestClient from '@/plugins/requestClient';
import ToastAlert from '@/plugins/ToastUI.js';

import { getCurrentLanguage } from '@/plugins/language.js';
import debug from '@/plugins/Logger.js';

const current = getCurrentLanguage();

debug.log("current",current);

library.add(far,fas);
dom.watch();

const toastOptions 	= {
  position: "bottom-right",
	timeout: 10000,
	pauseOnHover: true
};

const feather = require('feather-icons');
feather.replace();

(async function() {
  const configuration = await loadAndStoreConfigData();
  const favicon = document.querySelector('link[rel="shortcut icon"]') || document.createElement('link');
  favicon.setAttribute('rel', 'shortcut icon');
  favicon.setAttribute('type', 'image/x-icon');
  favicon.setAttribute('href', configuration.siteFavicon);
  document.head.appendChild(favicon);
  
  const eventBus  = mitt();
  const app       = createApp(App);

  //---------------------------------------------------------------------
  //                   Module Feature Function Permission
  //---------------------------------------------------------------------

  // Define the shared permissions object
  const permissions = {
    'itemcode1': true,
    'itemcode2': false,
  };

  // Function 1: Simply returns true or false based on permission
  function hasAccess(itemCode) {
    return permissions[itemCode] !== false;
  }

  // Function 2: Throws an error if access is denied
  function assertAccess(itemCode) {
    if (permissions[itemCode] === false) {
      throw new Error('Access denied');
    }
    return true;
  }

  // Directive
  app.directive('access', {
    mounted(el, binding) {
      const itemCode = binding.value;
      if (!hasAccess(itemCode)) {
        el.remove();
      }
    }
  });

  // Globally available in Vue
  app.config.globalProperties.$access = assertAccess;

  //---------------------------------------------------------------------
  //                   Module Feature Function Permission
  //---------------------------------------------------------------------

  app.directive('tooltip', {
    mounted(el, binding) {
      const [tooltipText, position] = binding.value;
  
      // Create tooltip element
      const tooltipEl = document.createElement('div');
      tooltipEl.textContent = tooltipText;
      tooltipEl.className = `tooltip ${position || 'top'}`; // Default to 'top' if no position provided
  
      // Style the tooltip element
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.backgroundColor = '#333';
      tooltipEl.style.color = '#fff';
      tooltipEl.style.padding = '5px';
      tooltipEl.style.borderRadius = '4px';
      tooltipEl.style.whiteSpace = 'nowrap';
      tooltipEl.style.zIndex = '1000';
      tooltipEl.style.display = 'none';
      tooltipEl.style.pointerEvents = 'none'; // Make sure the tooltip itself doesn't interfere with pointer events
  
      // Add tooltip to the document
      document.body.appendChild(tooltipEl);
  
      // Positioning logic
      const showTooltip = () => {
        const rect = el.getBoundingClientRect();
        let top = rect.top - tooltipEl.offsetHeight - 5;
        let left = rect.left + (rect.width - tooltipEl.offsetWidth) / 2;
  
        // Update tooltip position
        tooltipEl.style.top = `${top}px`;
        tooltipEl.style.left = `${left}px`;
        tooltipEl.style.display = 'block';
      };
  
      const hideTooltip = () => {
        tooltipEl.style.display = 'none';
      };
  
      // Event listeners to show/hide tooltip
      el.addEventListener('mouseenter', showTooltip);
      el.addEventListener('mouseleave', hideTooltip);
    },
    unmounted() { // Removed the unused 'el' parameter
      const tooltipEl = document.querySelector('.tooltip');
      if (tooltipEl) {
        tooltipEl.remove();
      }
    }
  });

  
  // Define a global variable
  app.config.globalProperties.hostkey   = key;
  app.config.globalProperties.$Key      = key;
  app.config.globalProperties.$Toast    = ToastAlert;
  app.config.globalProperties.$Storage  = storageManager;
  app.config.globalProperties.$Request  = new requestClient(false);
  
  // Hide warnings
  app.config.warnHandler = () => {};

  app
  .use(router)
  .use(BackToTop)
  .use(Toast, toastOptions)
  //.use(ReadMore)
  .use(VueSweetalert2)
  .use(VueCryptojs)
  .provide('apiServerName', "xxx")
  .provide('config', configuration)
  .use(pageTitle({suffix: '/ ' + configuration.siteName, mixin: true,}))
  // .use(VueMarkdownEditor)
  .use(TooltipPlugin)
  .use(Tooltip)
  .use(ContextMenu, { zIndex: 999999,maxWidth: 200 })
  .use(store) // Use the Vuex store
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Storage, {
      namespace: '_global_',
      name: 'ls',
      storage: 'local',
    }
  )
  .mount('#app');

  app.config.globalProperties.eventBus = eventBus;
  app.provide('eventBus', eventBus);

  await router.isReady();

  app.mixin({
    created() {
      this.eventBus = this.eventBus || this.$appContext.app.config.globalProperties.eventBus;
    },
  });

})();
