<template>

    <div class="space-y-2 mb-4 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
        <div class="flex sm:items-baseline sm:space-x-4">
            <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">แบบฟอร์มการลงทะเบียน</h1>
        </div>
    </div>
    
    </template>
    <script>
    </script>