export default function Toast(option) {
    const typeToIcon = {
        success: require('@/assets/images/success-tick.svg'),
        error: require('@/assets/images/error.svg'),
        pending: require('@/assets/images/sand-clock.svg'),
    };

    const typeToColor = {
        success: 'bg-green-600',
        error: 'bg-red-600',
        pending: 'bg-gray-800',
    };

    // Define the CSS animation
    const style = document.createElement('style');
    style.innerHTML = `
    @keyframes slow-spin {
        0%, 20% { transform: rotate(0deg); }
        25%, 45% { transform: rotate(45deg); }
        50%, 70% { transform: rotate(90deg); }
        75%, 95% { transform: rotate(135deg); }
        100% { transform: rotate(180deg); }
    }
    `;
    document.head.appendChild(style);

    const iconURL       = typeToIcon[option.type] || typeToIcon['pending'];
    const colorClass    = typeToColor[option.type] || 'bg-gray-800';

    const element = document.createElement('div');
    element.classList.add('fixed', 'inset-0', 'flex', 'items-center', 'justify-center', 'z-50');

    document.body.appendChild(element);

    element.classList.add('fade-in');
    element.classList.remove('fade-out');

    const iconElement = document.createElement('img');
    iconElement.src = iconURL;
    iconElement.style.width = '20px';
    iconElement.style.height = '20px';
    iconElement.style.filter = 'invert(1)';

    if (option.type === 'pending') {
        iconElement.style.animation = 'slow-spin 1s linear infinite';
    }

    const textElement = document.createElement('span');
    textElement.className = 'ml-2 text-sm';
    textElement.textContent = option.message;

    const contentElement = document.createElement('div');
    contentElement.className = 'flex items-center';
    contentElement.appendChild(iconElement);
    contentElement.appendChild(textElement);

    const toastElement = document.createElement('div');
    toastElement.className = `${colorClass} text-white py-2 px-4 rounded-lg shadow-lg`;
    toastElement.appendChild(contentElement);

    element.appendChild(toastElement);

    let autohideTimeout = null;

    const toast = {
        element,
        hide: function(message, type, timeout = 600, callback = () => {}) {
            clearTimeout(autohideTimeout);
            // Check if the iconElement is currently rotating
            if (iconElement.style.animation.includes('slow-spin')) {
                // Remove the rotation
                iconElement.style.animation = '';
            }
            if (message) {
                textElement.textContent = message;
            }
            if (type) {
                const newIconURL        = typeToIcon[type] || typeToIcon['pending'];
                const newColorClass     = typeToColor[type] || 'bg-gray-800';
                iconElement.src         = newIconURL;
                toastElement.className  = `${newColorClass} text-white py-2 px-4 rounded-lg shadow-lg`;
            }
            setTimeout(() => {
                element.classList.remove('fade-in');
                element.classList.add('fade-out');
                setTimeout(() => {
                    element.remove();
                    callback();
                }, timeout);
            }, 600);
        }
    };

    if (option.autohide) {
        autohideTimeout = setTimeout(() => {
            toast.hide();
        }, 600);
    }

    return toast;
}