const slug  = "client";
const title = "API Client";

export default [
  {
    path: `/${slug}`,
    name: `${slug}`,
    redirect: `/${slug}/dashboard`,
    description: "API Client",
    groups: 'origin',
    default: true,
    hasSubmenu: true,
    hasDashboard: false,
    inApp: 'yes',
    inTop: 'yes',
    meta: {
      inMenu: true,
      title: title,
      icon: "link",
      role: ['root'],
    },
    children: [
      {
        path: 'dashboard',
        name: `${slug}-dashboard`,
        component: () => import('./view/Index.vue'), // Dynamic import
        meta: {
          inMenu: false,
          parent: title,
          page: `${slug}`,
          main: title,
          title: `Origin Dashboard`,
          type: 'page',
          auth: true,
          icon: "warehouse",
        }
      },
      {
        path: 'key',
        name: `${slug}-key`,
        component: () => import('./view/Index.vue'), // Dynamic import
        meta: {
          inMenu: true,
          parent: title,
          page: `${slug}/key`,
          main: title,
          title: `Client Key`,
          type: 'page',
          auth: true,
          icon: "key",
        }
      },
      {
        path: 'key/add',
        name: `${slug}-key-add`,
        component: () => import('./view/Index.vue'), // Dynamic import
        meta: {
          inMenu: false,
          parent: title,
          page: `${slug}/key`,
          main: title,
          title: `เพิ่ม Client Key`,
          type: 'page',
          auth: true,
          icon: "plus",
        }
      },
      {
        path: 'key/detail/:id',
        name: `${slug}-key-detail`,
        component: () => import('./view/Index.vue'), // Dynamic import
        meta: {
          inMenu: false,
          parent: title,
          page: `${slug}/key`,
          main: title,
          title: `รายละเอียด Client Key`,
          type: 'page',
          auth: true,
          icon: "user-shield",
        }
      },
      {
        path: 'config',
        name: `${slug}-config`,
        component: () => import('./view/Index.vue'), // Dynamic import
        meta: {
          inMenu: true,
          parent: title,
          page: `${slug}/config`,
          main: title,
          title: `Client Key Config`,
          type: 'page',
          auth: true,
          icon: "cog",
        }
      }
    ]
  }
]
