import CryptoJS from 'crypto-js';

class StorageManager {
  constructor(storageType = 'localStorage') {
    this.useEncryption = false; // Default to using encryption
    this.secretKey = 'GZp5+YcJml0SW5JFQVZ9TQ==';
    this.storage = this.getStorageInstance(storageType);
  }

  get(name, key = null) {
    const item = this.storage.getItem(name);
    return this.retrieveItem(item, key);
  }

  set(name, item) {
    this.storeItem(name, item);
  }

  update(name, newItem) {
    const existingItem = this.get(name);
    if (existingItem) {
      const mergedItem = { ...existingItem, ...newItem };
      this.set(name, mergedItem);
    } else {
      this.set(name, newItem);
    }
  }

  delete(name) {
    this.storage.removeItem(name);
  }

  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
  }

  decrypt(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  setEncryption(enabled) {
    this.useEncryption = enabled;
  }

  // Utility function to get the appropriate storage instance
  getStorageInstance(storageType) {
    if (storageType === 'localStorage') {
      return window.localStorage;
    } else if (storageType === 'sessionStorage') {
      return window.sessionStorage;
    } else if (storageType === 'cookies') {
      return document.cookie;
    } else {
      throw new Error('Invalid storage type');
    }
  }

  // Utility functions for working with data
  retrieveItem(item, key) {
    if (!item) {
      return null;
    }
    const data = this.useEncryption ? this.decrypt(item) : JSON.parse(item);
    if (key) {
      return data[key];
    }
    return data;
  }

  storeItem(name, item) {
    const data = this.serializeItem(item);
    this.storage.setItem(name, data);
  }

  serializeItem(item) {
    return this.useEncryption ? this.encrypt(item) : JSON.stringify(item);
  }
}

const storageManager = new StorageManager('localStorage'); // Default to localStorage

export default storageManager;
