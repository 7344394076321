const slug  = "resource";
const title = "Site Resource";

export default [
  {
    path: `/${slug}`,
    name: `${slug}`,
    redirect: `/${slug}/dashboard`,
    description: "Cloud Space",
    groups: 'origin',
    default: true,
    hasSubmenu: true,
    hasDashboard: false,
    inApp: 'yes',
    inTop: 'yes',
    meta: {
      inMenu: true,
      title: title,
      icon: "rocket",
      role: ['root'],
    },
    children: [
      {
        path: 'space',
        name: `${slug}-space`,
        component: () => import('./view/Space.vue'), // Dynamic import
        meta: {
          inMenu: true,
          parent: title,
          page: `${slug}/space`,
          main: title,
          title: `Cloud Space`,
          type: 'page',
          auth: true,
          icon: "server",
        }
      },
      {
        path: 'package',
        name: `${slug}-package`,
        component: () => import('./view/Package.vue'), // Dynamic import
        meta: {
          inMenu: true,
          parent: title,
          page: `${slug}/package`,
          main: title,
          title: `Resource Package`,
          type: 'page',
          auth: true,
          icon: "box-open",
        }
      },
    ]
  }
]
